<template >
    <div id="notification" >
       <div role="alert" class="alert alert-custom alert-white alert-shadow gutter-b" v-for="(item, i) in notificationlist" v-bind:key="i">
           <div class="alert-icon alert-icon-top">
              <span class="index-image notification-image" v-loadimage="item.data.images"></span>
           </div>
           <div class="alert-text">               
               <p class="text-dark text-hover-primary mb-1 font-size-lg">{{ item.data.productName }}</p>
               <p><span class="label label-inline label-pill label-info label-rounded mr-2"> INFO: </span> 
               <span>The layout builder helps to configure the layout with preferred options and preview it in real time. The configured layout options will be saved until you change or reset them. To use the layout builder choose the layout options and click the <code>Preview</code> button to preview the changes.</span>
               </p>
           </div>
       </div>
        
            <div class="row">
            <div class="col-sm-9 col-xs-12">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="lg"
                  class="my-0"
                  @change="handlePageChange"
                ></b-pagination>
            </div>
            <div class="col-sm-3 col-xs-12">
              <b-form-group
                label="Per page"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="6"
                label-align-sm="right"
                label-size="lg"
                label-for="perPageSelect"
                class="mb-0"
              >
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="lg"
                  :options="pageOptions"
                  @change="handlePerPageChange"
                ></b-form-select>
              </b-form-group>
            </div>
           
        
        
        </div>
        
    </div>
</template>

<script>

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getAllnotification} from "@/api/inventory";
export default {
    name : "Notification",
    data() {
    return {
      loading:false,
      images: '/ctshub/media/bg/profile-img.png',
      tabIndex: 0,
      notificationlist: [],
     title : 'Notification message',
      totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15],
      code3: {
       
    rows1: 100,
        currentPage1: 1,
        rows2: 100,
        currentPage2: 1,
        rows3: 100,
        currentPage3: 1
      },
      
    };
    },
    methods :{
        getAllnotification(){
      this.loading = true
      getAllnotification().then(response => {
        console.log(response);
        this.notificationlist = response.data.data.data        
        this.currentPage = response.data.data.current_page
        this.totalRows = response.data.data.total
        this.perPage = 10
        
        this.loading = false
      })
    },
    fetchData(p) {
        this.loading = true
        let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
        if(this.perPage){
          params.per_page = this.perPage;
        }
        getAllnotification(params).then(response => {
          this.notificationlist = response.data.data.data         
          this.currentPage = response.data.data.current_page
          this.totalRows = response.data.data.total
          this.perPage = response.data.data.per_page
          this.loading = false
          this.$set(this.notificationlist, '_showDetails', true)
        })
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      handlePageChange(p){
        this.fetchData(p)
      },
      handlePerPageChange(){
        this.fetchData(1)
      },
    },
     mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      
      { title: "Notifications" }
    ]);
  },
  created(){
     this.fetchData(1);
 this.getAllnotification();
  },

  watch:{
      filter : function(n){
        if(!n){
          this.fetchData(1)
        }
      }
    },
  
}
</script>